<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          :loading="loading"
          hide-default-footer
          :page.sync="page"
          :items-per-page="table.itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Pengajuan Pinjaman</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
            ></v-text-field> -->
              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="700px">
                <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
              </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-simple-table dense style="width: 100%">
                          <tbody>
                            <tr>
                              <td><b>Kode Anggota</b> : {{ editedItem.kode_anggota }}</td>
                              <td><b>Nama</b> : {{ editedItem.nama_anggota }}</td>
                              <td>
                                <b>Wilayah</b> :
                                <span v-if="editedItem.wilayah_luar === '1'" style="color: red">{{
                                  editedItem.wilayah
                                }}</span>
                                <span v-else>{{ editedItem.wilayah }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td><b>Tgl Lahir</b> : {{ formatDate(editedItem.tgl_lahir) }}</td>
                              <td><b>Divisi</b> : {{ editedItem.divisi }}</td>
                              <td><b>Jenis Upah</b> : {{ editedItem.jenis_upah.value }}</td>
                            </tr>
                            <tr>
                              <td><b>TGL Masuk</b> : {{ formatDate(editedItem.tgl_masuk) }}</td>
                              <td><b>Jabatan</b> : {{ editedItem.jabatan }}</td>
                              <td>
                                <b>Jumlah Upah : </b>{{ new Intl.NumberFormat(['id']).format(editedItem.jumlah_upah) }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <v-simple-table dense style="width: 100%; margin-top: 20px">
                          <tbody>
                            <tr>
                              <td><b>Kode Pengajuan</b> : {{ editedItem.kode_pengajuan }}</td>
                              <td><b>Tgl Pengajuan</b> : {{ formatDate(editedItem.tgl_pengajuan) }}</td>
                              <td><b>Program</b> : {{ editedItem.jenis_pinjaman }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Besar Pinjam</b> :
                                {{ new Intl.NumberFormat(['id']).format(editedItem.besar_pinjam) }}
                              </td>
                              <td>
                                <b>Jangka Waktu</b> :
                                {{
                                  editedItem.jangka_waktu +
                                  ' ' +
                                  (editedItem.jenis_upah.value != null
                                    ? editedItem.jenis_upah.value.replace('AN', '')
                                    : '')
                                }}
                                <!-- {{ editedItem.jangka_waktu }} -->
                              </td>
                              <td><b>Status</b> : {{ editedItem.status }}</td>
                            </tr>
                            <tr>
                              <td><b>Tanda Tangan</b> :</td>
                              <td colspan="2">
                                <img :src="apiRootFolder + '/' + editedItem.ttd" />
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        <v-col cols="12" v-if="editedItem.status == 'PENGAJUAN'">
                          <v-form class="multi-col-validation">
                            <v-row>
                              <v-col cols="4" sm="3" md="3" style="display: flex; font-weight: bold"
                                >Catatan HRD
                              </v-col>
                              <v-col cols="6" sm="6" md="6">
                                <v-select
                                  :items="catatan_hrds"
                                  hide-details="auto"
                                  outlined
                                  dense
                                  v-model="catatan_hrd"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-col>
                        <v-simple-table v-else dense style="width: 100%; margin-top: 20px">
                          <tbody>
                            <tr>
                              <td><b>TGL ACC</b> : {{ editedItem.tgl_acc }}</td>
                              <td>
                                <b>Besar ACC</b> :
                                {{ new Intl.NumberFormat(['id']).format(editedItem.besar_pinjam_acc) }}
                              </td>
                              <td>
                                <b>Jangka Waktu ACC</b> :
                                {{
                                  editedItem.jangka_waktu_acc +
                                  ' ' +
                                  (editedItem.jenis_upah.value != null
                                    ? editedItem.jenis_upah.value.replace('AN', '')
                                    : '')
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" v-if="editedItem.status == 'PENGAJUAN'" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
                  <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
            </v-toolbar>

            <v-form class="ml-4">
              <v-row>
                <v-col cols="1" md="1">
                  <label for="firstname">Status</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="status"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.status"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Jenis Upah</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="jenis_upahs"
                    outlined
                    dense
                    v-model="table.jenis_upah"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <template v-slot:item.anggota="{ item }">
            {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.tgl_pengajuan="{ item }">
            {{ formatDate(item.tgl_pengajuan) }}
          </template>
          <template v-slot:item.besar_pinjam="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_pinjam) }}</div>
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" title="Lihat"> {{ icons.mdiEye }} </v-icon>
            <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="initialize"> Reload</v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
const apiRootFolder = process.env.VUE_APP_APIROOT
export default {
  setup() {
    return {
      icons: {
        mdiEye,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Anggota', value: 'anggota', sortable: false },
      { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
      { text: 'Tanggal Pengajuan', value: 'tgl_pengajuan', sortable: false },
      { text: 'Besar Pinjam', value: 'besar_pinjam', sortable: false },
      { text: 'Jangka Waktu', value: 'jangka_waktu', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Catatan HRD', value: 'rekomendasi_hrd', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    desserts: [],
    loading: false,
    page: 1,
    table: {
      pageCount: 0,
      itemsPerPage: 25,
      // search: '',
      status: 'SEMUA',
      jenis_upah: 'SEMUA',
    },
    length_menus: [25, 50, 100, 500, 1000],
    jenis_upahs: ['SEMUA', 'HARIAN', 'MINGGUAN', 'BULANAN'],
    status: ['SEMUA', 'PENGAJUAN', 'DITERIMA', 'DITOLAK'],
    catatan_hrds: ['DITERIMA', 'DIPERTIMBANGKAN', 'DITOLAK'],
    catatan_hrd: 'DITERIMA',

    editedIndex: -1,
    editedItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: 2,
      jenis_upah: { text: 'HARIAN', value: 'HARIAN' },
      aktif: true,
    },
    defaultItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: '',
      jenis_upah: '',
      aktif: '',
    },
    jenis_upah: [
      { text: 'HARIAN', value: 'HARIAN' },
      { text: 'MINGGUAN', value: 'MINGGUAN' },
      { text: 'BULANAN', value: 'BULANAN' },
    ],
    search: '',
    apiRootFolder: apiRootFolder,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Program Pinjaman' : 'Detail Pengajuan'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getDataFromApi()
    },
    optionChange() {
      this.page = 1
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true

      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      // form.append('search', this.search)
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('status', this.table.status)
      form.append('jenis_upah', this.table.jenis_upah)

      axios
        .post(`${apiRoot}/api/Pengajuan/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.jenis_upah = { text: item.jenis_upah, value: item.jenis_upah }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('rekomendasi_hrd', this.catatan_hrd)
        axios
          .post(`${apiRoot}/api/Pengajuan/rekomendasiPengajuanHRD`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        // const headers = {
        //   Authorization: this.token,
        // }
        // const form = new FormData()
        // form.append('nama', this.editedItem.nama)
        // form.append('maks_pinjam', this.editedItem.maks_pinjam)
        // form.append('lama_angsuran', this.editedItem.lama_angsuran)
        // form.append('bunga', this.editedItem.bunga)
        // form.append('aktif', this.editedItem.aktif)
        // form.append('jenis_upah', this.editedItem.jenis_upah.value)
        // axios
        //   .post(`${apiRoot}/api/JenisPinjaman/add`, form, { headers })
        //   .then(response => {
        //     if (response.data.code === 401) {
        //       this.$store.dispatch('logout', 'Session berakhir!')
        //     } else if (response.data.code === 200) {
        //       this.search = ''
        //       this.options.page = 1
        //       this.getDataFromApi()
        //       this.close()
        //     } else {
        //       alert(response.data.message)
        //     }
        //   })
        //   .catch(error => {
        //     alert(error)
        //   })
      }
    },

    aktifCheck() {},
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
